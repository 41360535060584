<template lang="pug">
#bienvenidaRoot
	
	//- .cubreStatusBar
	slot
</template>

<script lang="ts" setup>
</script>

<script lang="ts">
export default defineComponent({
	name: 'BienvenidaLayout'
})
</script>

<style lang="sass" scoped>
#bienvenidaRoot
	min-height: 100vh
	min-height: 100dvh
</style>
